import { FocusZone, Link, MessageBar, MessageBarType } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect, Route, Switch } from 'react-router-dom';
import { getCustomTheme } from '../common/themes/selectors';
import ErrorCode from '../common/error-codes';
import LoadingContainer from '../common/loading-section';
import DashboardContainer from '../dashboard/dashboard-container';
import { commonMessages } from '../language/common-messages';
import { SchedulesContainer } from '../schedule/schedule-container';
import SettingsPageContainer from '../settings-page/settings-page-container';
import TemplateContainer from '../template/template-container';
import { UserListContainer } from '../user-list/user-list-container';
import { Routes } from '../utils/routes';
import { VmListContainer } from '../vm-list/vm-list-container';
import { LabAppNav } from './lab-app-nav';
import LabCreatingView from './lab-creating-view';
import PermissionPropagationView from './lab-creating-permission-propagating-view';
import LinkedLmsUpdateErrors from '../common/linked-lms-update-errors';
import './lab-app.css';
export const LabAppMessageBar = (props) => {
    const { showCovidBanner, isBlockedLab, isWriteable, isMoving, onDismissedCovidBanner, templateCreateError, isVmActionsEnabled, } = props;
    const intl = useIntl();
    const { messageBarStyles } = getCustomTheme();
    if (!isWriteable && !isVmActionsEnabled) {
        return (<MessageBar messageBarType={MessageBarType.blocked}>
                <FormattedMessage id="LabAppReadOnlyMessage" defaultMessage="You do not have permission to make any changes to this lab. Contact your Lab Services administator to get additional permissions." description="Message displayed in a message bar in the lab view when a user does not have write permissions to a lab."/>
            </MessageBar>);
    }
    if (isBlockedLab) {
        return (<MessageBar messageBarType={MessageBarType.blocked} styles={messageBarStyles}>
                <FormattedMessage id="LabAppBlockedMessage" defaultMessage="Unfortunately, this lab was affected by the recent Azure Lab Services outage and is not recoverable. Please delete it and create a new lab. For detailed steps on how to proceed, please see our{blogPostLink}. We are deeply sorry for the inconvenience this has caused." description="Message displayed in a message bar in the lab view when a lab is blocked due to an outage. Note: the missing space before {blogPostLink} is intentional." values={{
            blogPostLink: (<Link target="_blank" href="https://aka.ms/azlabs-outage">
                                <FormattedMessage id="LabAppBlockedMessageLinkText" defaultMessage="outage blog post" description="Text for the {blogPostLink} parameter in LabAppBlockedMessage."/>
                            </Link>),
        }}/>
            </MessageBar>);
    }
    if (isMoving) {
        return (<MessageBar messageBarType={MessageBarType.blocked}>
                <FormattedMessage id="LabAppMovingRegionsMessage" defaultMessage="This lab is being moved to another Azure region. The lab will be read-only until the move is finished. Your lab users can't use their virtual machines at this time." description="Message displayed in a message bar in the lab view when a lab is being moved between Azure regions."/>
            </MessageBar>);
    }
    if (!!templateCreateError) {
        switch (templateCreateError.code) {
            case ErrorCode.PeerVnetAddressRangeOutOfAddresses:
                return (<MessageBar messageBarType={MessageBarType.blocked} styles={messageBarStyles}>
                        <FormattedMessage id="CreateTemplateErrorPeerVnetAddressRangeOutOfAddressesMessage" defaultMessage="Lab creation failed because the IP address range is too narrow in this lab account. Please contact your Lab Services administator to widen the range, and recreate this lab. {blogPostLink}" description="Message displayed in a message bar in the lab view when a lab is blocked due template creation failure. Note: the missing space before {blogPostLink} is intentional." values={{
                    blogPostLink: (<Link target="_blank" href="https://aka.ms/azlabs-vnetaddressrange">
                                        <FormattedMessage id="CreateTemplateErrorPeerVnetAddressRangeOutOfAddressesMessageLinkText" defaultMessage="Learn more" description="Text for the {blogPostLink} parameter in CreateTemplateErrorPeerVnetAddressRangeOutOfAddressesMessage."/>
                                    </Link>),
                }}/>
                    </MessageBar>);
            case ErrorCode.PasswordNotValid:
                return (<MessageBar messageBarType={MessageBarType.blocked}>
                        <FormattedMessage id="CreateTemplateErrorPasswordNotValid" defaultMessage="Lab creation failed because the password wasn't complex enough." description="Message displayed in a message bar in the lab view when a lab is blocked due to a password not meeting policy."/>
                    </MessageBar>);
            case ErrorCode.VMAgentStatusCommunicationError:
                return (<MessageBar messageBarType={MessageBarType.blocked}>
                        <FormattedMessage id="CreateTemplateVmAgentNoCommunicationError" defaultMessage="Communication could not be established with the VM agent. Please verify that the VM agent is enabled and functioning." description="Occurs when the agent cannot be communicated with."/>
                    </MessageBar>);
            default:
                return (<MessageBar messageBarType={MessageBarType.blocked}>
                        <FormattedMessage id="CreateTemplateErrorDefaultMessage" defaultMessage="Lab creation failed. Contact your lab administrator or try creating again." description="Message displayed in a message bar in the lab view when a lab failed to create."/>
                    </MessageBar>);
        }
    }
    if (showCovidBanner) {
        return (<MessageBar messageBarType={MessageBarType.warning} onDismiss={onDismissedCovidBanner} dismissButtonAriaLabel={intl.formatMessage(commonMessages.close)}>
                <FormattedMessage {...commonMessages.covidWarning}/>
            </MessageBar>);
    }
    return null;
};
export const LabApp = (props) => {
    const { labAppViewModel, navigateRoute, onDismissedCovidBanner, clearUpdateLinkedLmsError } = props;
    // ****** IMPORTANT ******Keep the flag as false until we're ready to show the retirement message******
    const shouldShowRetirementMessage = true;
    const { route, search, selectedLabId, isInitializing, showCovidBanner, showOldV1GpuLabBanner, isBlockedLab, isWriteable, isMoving, templateCreateError, isTeamsOrLmsIntegrationEnabled, isVNextLabCreating, isVnextLabPermissionPropagating, imageName, cores, memoryInGB, storageSize, storageType, labSkuInfo, updateLinkedLmsErrors, isVmActionsEnabled, } = labAppViewModel;
    const mainRef = React.useRef();
    const intl = useIntl();
    const [navExpanded, updateNavExpanded] = React.useState(true);
    if (isInitializing && !isVnextLabPermissionPropagating) {
        return <LoadingContainer />;
    }
    // move focus to the main content when the route changes
    const onNavigate = (route) => {
        mainRef.current?.focus();
        navigateRoute(route);
    };
    return (<div id="lab-app-container">
            <div id="lab-app-content">
                <div id="lab-app-header">
                    {shouldShowRetirementMessage && (<MessageBar messageBarType={MessageBarType.severeWarning}>
                            <FormattedMessage id="ServiceRetirementLabAppMessage" defaultMessage="Azure Lab Services will be retired on June 28th, 2027. However, you can continue to use your existing lab accounts and lab plans till that date. {RetirementMessageLink}" description="Retirement message displayed in a message bar for lab app." values={{
        RetirementMessageLink: (<Link target="_blank" style={{ paddingLeft: '0px' }} href={'https://aka.ms/azlabs-retirementguide'}>
                                            <FormattedMessage id="ServiceRetirementLabAppLink" defaultMessage="Learn more" description="a link to the service retirement public document."/>
                                        </Link>),
    }}/>
                        </MessageBar>)}
                    <MessageBar messageBarType={MessageBarType.blocked}>
                        <FormattedMessage id="VmOperationsLabAppOutageMessage" defaultMessage="On October 12th, 2024, we will be updating our system between 7:00 AM and 6:00 PM CST. This maintenance will only affect the creation of new Canvas labs or the management of existing Canvas labs through our service. The Canvas labs functionality will be available again after the update. For more information, please refer to {blogPostLink}" description="Message displayed in a message bar." values={{
        blogPostLink: (<Link target="_blank" href="https://aka.ms/canvasupdate">
                                        <FormattedMessage id="VmOperationsLabAppOutageLinkText" defaultMessage="blog post" description="Text for the {blogPostLink} parameter in VmOperationsInterruptedMessage."/>
                                    </Link>),
    }}/>
                    </MessageBar>
                    <LabAppMessageBar templateCreateError={templateCreateError} showCovidBanner={showCovidBanner} isBlockedLab={isBlockedLab} isWriteable={isWriteable} isMoving={isMoving} onDismissedCovidBanner={onDismissedCovidBanner} isVmActionsEnabled={isVmActionsEnabled}/>
                    {showOldV1GpuLabBanner && (
    // The banner will continue to show up, we're not caching this value.
    <MessageBar messageBarType={MessageBarType.severeWarning} dismissButtonAriaLabel={intl.formatMessage(commonMessages.close)}>
                            <FormattedMessage {...commonMessages.oldV1GpuLabWarning} values={{
        gpuOutage: (<Link href={'https://aka.ms/V1GPUIssues'}>
                                            <FormattedMessage id="OldV1GpuLabWarningLink" defaultMessage="More information." description="Link to documentation describing the GPU outage with older GPU labs."/>
                                        </Link>),
    }}/>
                        </MessageBar>)}
                    {updateLinkedLmsErrors.size > 0 && (<LinkedLmsUpdateErrors errors={updateLinkedLmsErrors} clearError={clearUpdateLinkedLmsError}/>)}
                </div>
                <div id="lab-app-body">
                    {!isVNextLabCreating && !isVnextLabPermissionPropagating && (<>
                            {!isTeamsOrLmsIntegrationEnabled && (<div id="lab-app-nav">
                                    <FocusZone className="lab-app-focus-zone">
                                        <LabAppNav route={route} selectedLabId={selectedLabId} navigateRoute={onNavigate} expanded={navExpanded} updateExpanded={updateNavExpanded}/>
                                    </FocusZone>
                                </div>)}
                            
                            <div ref={mainRef} id="lab-app-page">
                                <Switch>
                                    <Route exact={true} path={Routes.Dashboard()} render={() => <DashboardContainer />}/>
                                    <Route exact={true} path={Routes.Template()} render={() => <TemplateContainer labId={selectedLabId}/>}/>
                                    <Route exact={true} path={Routes.Schedule()} render={() => <SchedulesContainer />}/>
                                    <Route exact={true} path={Routes.Users()} render={() => <UserListContainer labId={selectedLabId}/>}/>
                                    <Route exact={true} path={Routes.VirtualMachines()} render={() => <VmListContainer labId={selectedLabId}/>}/>
                                    <Route exact={true} path={Routes.Settings()} render={() => <SettingsPageContainer />}/>
                                    <Route path={Routes.Lab()} render={() => (<Redirect to={{ pathname: Routes.Dashboard(selectedLabId), search }}/>)}/>
                                </Switch>
                            </div>
                        </>)}
                    {!!isVNextLabCreating && !isVnextLabPermissionPropagating && (<LabCreatingView memorySize={memoryInGB} imageName={imageName} cores={cores} storageSize={storageSize} storageType={storageType} labSkuInfo={labSkuInfo}/>)}
                    {!!isVnextLabPermissionPropagating && (<PermissionPropagationView memorySize={memoryInGB} imageName={imageName} cores={cores} storageSize={storageSize} storageType={storageType} labSkuInfo={labSkuInfo}/>)}
                </div>
            </div>
        </div>);
};
export default LabApp;
